.Toastify__toast--success {
    background: #00b4ae !important;
 }
.Pergunta-select-row{
    height: 85px !important;
}
.Pergunta-Field{
    border:none !important;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
    opacity: 0.7 !important;
    font-weight: bolder;
}
.Pergunta-Field:focus{
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    outline: none;
}
.Pergunta-Error{
    color: #ff8080;
    font-size: 12px;
}
.Pergunta-Select{
    border:none;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
    margin-right: 19px;
}
.Pergunta-Select2{
    border:none;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
}
.conteudo-tamanho{
    width: 90%;
    margin: 0 auto !important;
}
.form-pergunta{
    width: 90%;
}