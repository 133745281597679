@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap);
.align-content-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
}
.flex-column li {
    margin: 10px 0px 10px -5px;
    padding: 1px 0px 4px 31px;
}

.flex-column li a {
    color: #24b3af;
    text-decoration: none;
}
.flex-column li a svg{
    margin-right: 10px;
    width: 30px;
    height: 30px;
}
.is-active {
    flex-direction: row;
    background: #24b3af
                 url(/static/media/barra_menu.10ad52b3.svg)
                 no-repeat left;
    display: block;
    color: #fff !important;
    text-decoration: none !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 5px 0px 5px -26px;
    padding: 10px 0px 10px 25px;
}

header#main-header{
    height: 100px;
    width: 100%;
    background: #00b4ae;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    display: flex;
    /* justify-content: center; */
}
.middle{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Novo-btn{
    background: #f7a03f;
    color: #ffffff;
    border:none;
    padding: .5rem 2rem;
    border-radius: 5px;
    margin-top: 1rem;
    font-weight: bold;
    font-size: 24px;
    width: 75%;
    height: 60px;
    align-content: center;
    float: right;
}
.btn-patrocinador{
    padding: 0.1rem 2rem !important;
}
.Novo-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    background:rgba(247,160,63,.85);
}
.Novo-btn:focus{
    outline: 0;
}
.Novo-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}
.Back-btn{
    background: #00b4ae;
    color: #ffffff;
    border: 0;
    width: 40px;
    height: 40px;
}
.Back-btn:hover{
    cursor: pointer;
}
.Back-btn:focus{
    outline: 0;
}
.Back-btn-consulta{
    background: #00b4ae;
    color: #ffffff;
    border: 0;
    width: 100%;
    height: 80px;
}

.Back-btn-consulta h1{
    background: #00b4ae;
    color: #ffffff;
    border: 0;
    width: 100%;
    height: 80px;
}
.not-found-container {
    display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    height: 100vh;
    /* margin: 0 15px; Propriedade para corrigir margem do Reactstrap */
    text-align: center;
    width: 100%;
}
.not-found-container h2 {
    font-weight: bold;
}
.grid-card h2 {
    color: #00b4ae;
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0;
  }
  
  .grid-card h3 {
    color: #f7a03f;
    font-size: 1rem;
  }
  
  .grid-card p {
    color: #b2b2b2;
    margin: 1rem 0 0;
  }

  .grid-card:hover{
    box-shadow: 0px 0px 5px 0px rgb(254 186 69) !important;
    cursor: pointer;
  }
  .grid-status-false{
    background-color: #e5e5e5 !important;
    cursor: pointer !important;
    color: #b2b2b2 !important;
  }
  .grid-status-false h3 {
    color: #b2b2b2;
  }
  .grid-status-false h2 {
    color: #b2b2b2;
  }
  .grid-status-false:hover{
    box-shadow: 3px 5px 5px -2px rgba(0,0,0,.15) !important;
  }
  .text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.no-gutters.row > .graph-card, /* Para vencer a hierarquia */
.graph-card {
    padding: 1rem;
}

.graph-card-size {
    max-width: 47% !important;
    margin: auto;
}

.graph-card .header {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
}

.graph-card .header h2 {
    color: var(--main-color);
    font-size: 1.8rem;
    font-weight: bold;
}

.graph-card .buttons-container input {
    display: none;
}
.graph-card .buttons-container label {
    background-color:#fff;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    font-size: .8rem;
    height: 2rem;
    line-height: 2rem;
    margin: 0;
    text-align: center;
    width: 5rem;
}
.graph-card .buttons-container label:hover {
    cursor: pointer;
}
.graph-card .buttons-container input:checked + label {
    background-color: var(--secondary-color);
    color: #fff;
    cursor: default;
}
.graph-card .buttons-container .firstOption + label {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
    border-right: 0;
}
.graph-card .buttons-container .quiteOption + label {
    border-right: 0;
    border-left: 0;
}
.graph-card .buttons-container .secondOption + label {
    border-left: 0;
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
}

.graph-card .custom-tooltip .label{
    background: var(--secondary-color);
    border-radius: 1rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    width: 12rem;
}
.graph-card .custom-tooltip .two-label,
.graph-card .custom-tooltip .two-label-inverted{
    font-size: 1.2rem;
}

.graph-card .recharts-legend-item.legend-item-0 .recharts-legend-item-text,
.graph-card .custom-tooltip .two-label span:first-child,
.graph-card .custom-tooltip .two-label-inverted span:last-child{ 
    color: var(--main-color);
    font-weight: 700;
 }

.graph-card .recharts-legend-item.legend-item-1 .recharts-legend-item-text,
.graph-card .custom-tooltip .two-label span:last-child,
.graph-card .custom-tooltip .two-label-inverted span:first-child{ 
    color: var(--secondary-color);
    font-weight: 700;
}

.recharts-legend-item-text {
    color: black !important;
}


/* Aaui */

.no-gutters.row > .graph-card, /* Para vencer a hierarquia */
.graph-card {
    padding: 1rem;
}

.graph-card-size {
    max-width: 47% !important;
    margin: auto;
}

.graph-card .header {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
}

.graph-card .header h2 {
    color: var(--main-color);
    font-size: 1.8rem;
    font-weight: bold;
}

.graph-card .buttons-container input {
    display: none;
}
.graph-card .buttons-container label {
    background-color:#fff;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    font-size: .8rem;
    height: 2rem;
    line-height: 2rem;
    margin: 0;
    text-align: center;
    width: 5rem;
}
.graph-card .buttons-container label:hover {
    cursor: pointer;
}
.graph-card .buttons-container input:checked + label {
    background-color: var(--secondary-color);
    color: #fff;
    cursor: default;
}
.graph-card .buttons-container .firstOption + label {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
    border-right: 0;
}
.graph-card .buttons-container .quiteOption + label {
    border-right: 0;
    border-left: 0;
}
.graph-card .buttons-container .secondOption + label {
    border-left: 0;
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
}

.graph-card .custom-tooltip .label{
    background: var(--secondary-color);
    border-radius: 1rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    width: 12rem;
}
.graph-card .custom-tooltip .two-label,
.graph-card .custom-tooltip .two-label-inverted{
    font-size: 1.2rem;
}

.graph-card .recharts-legend-item.legend-item-0 .recharts-legend-item-text,
.graph-card .custom-tooltip .two-label span:first-child,
.graph-card .custom-tooltip .two-label-inverted span:last-child{ 
    color: var(--main-color);
    font-weight: 700;
 }

.graph-card .recharts-legend-item.legend-item-1 .recharts-legend-item-text,
.graph-card .custom-tooltip .two-label span:last-child,
.graph-card .custom-tooltip .two-label-inverted span:first-child{ 
    color: var(--secondary-color);
    font-weight: 700;
}


/* Aaui */

.no-gutters.row > .counter-card, /* Para vencer a hierarquia */
.counter-card {
    display: flex;
    justify-content: space-between;
    max-width: 320px;
    padding: 1.5rem;
}

.counter-card svg {
    min-width: 60px;
}
.counter-card .info {
    display: flex;
        align-items: center;
        flex-flow: column;
}

.counter-card .info .count {
    color: var(--secondary-color);
    font-size: 2.4rem;
    font-weight: 900;
    line-height: 2.6rem;
}
.counter-card .info .legend {
    color: var(--main-color);
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
}

.no-gutters.row > .counter-card2, /* Para vencer a hierarquia */
.counter-card2 {
    /* display: flex; */
    justify-content: space-evenly;
    /* max-width: 320px; */
    padding: 1.5rem;
}

.counter-card2 svg {
    min-width: 60px;
}
.counter-card2 .info {
    display: flex;
        align-items: center;
        flex-flow: column;
}

.counter-card2 .info .count {
    color: var(--secondary-color);
    font-size: 2.4rem;
    font-weight: 900;
    line-height: 2.6rem;
}
.counter-card2 .info .legend {
    color: var(--main-color);
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.counter-card2 .info .sublegend {
    color: var(--secondary-color);
    font-size: .75rem;
    font-weight: 600;
    text-align: center;
}

.no-gutters.row > .table-content, /* Para vencer a hierarquia */
  .table-content {
    border-spacing: 0;
    width: 100%;
  }

  .table-content th{
        color: #A9A9A9;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        line-height: 24px;
        border-bottom: 1px solid #DFE2E9;  
        align-items: center;
      
  }
  .table-content td {
        margin: 0;
        padding: 0.5rem;
        color: #00072B;
        font-family: Montserrat;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        border-bottom: 1px solid #DFE2E9;
        font-family: Montserrat;
    }
    .rgt-text-truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .paginationin-content {
        justify-content: flex-end !important;
        color: #FFA140;
    }
    .button-paginationin {
        padding: 10px;
        border: 1px solid #FFA140;
        color: #FFA140;
        margin: 10px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 5px 5px 5px 5px #e8e8e8;
    }
    
.search-bar.with-filter {
    width: 100%;
    display: flex;
}

.search-bar.with-filter .input-group {
    align-items: center;
    height: 4rem;
}

.search-bar.with-filter .input-group-prepend {
    padding: 0 1rem;
}

.search-bar.with-filter .input-group input {
    border: 0;
    background-color: transparent;
    color: var(--contrast-color);
    font-size: 1.2rem;
    padding: 2rem 0;
}

.search-bar.with-filter .input-group input:focus  {
    box-shadow: none;
}

.search-bar.with-filter button,
.search-bar.with-filter button.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: var(--secondary-color);
    display: flex;
        align-items: center;
        justify-content: space-evenly;
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 3rem;
    max-width: 160px;
    min-width: 90px;
    width: 100%;
}
.search-bar.with-filter 
    button.btn-secondary:not(:disabled):not(.disabled):active {
    border: 0;
    box-shadow: none;
}

.pre-edition-li-card.nav-item {
    width: 100%;
}
.pre-edition-li-card.nav-item a {
    display: flex;
}
.pre-edition-li-card.nav-item a:hover{
    text-decoration: none;
}
.pre-edition-li-card .container {
    color: var(--secondary-color);
    font-size: 2rem;
    font-weight: 900;
    height: 6rem;
    padding: 1.5rem 2rem;
}

.pre-edition-li-card .container .icon-left,
.pre-edition-li-card .container .icon-right {
    color: var(--main-color);
}
.pre-edition-li-card .container img,
.pre-edition-li-card .container .icon-left {
    float: left;
    margin-right: 2.5rem;
}

.pre-edition-li-card .container .icon-right {
    float: right;
}

.edition-li-card .card {
    padding: 0 .5rem;
}

.edition-li-card .card-title {
    color: var(--main-color);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.6rem;
}
.edition-li-card .card-subtitle {
    color: var(--contrast-color);
    font-size: 1rem;
    font-weight: 100;
}

.edition-li-card .card-body:last-child {
    padding-top: 0;
}
.edition-li-card .card-body .nav,
.edition-li-card .card-body:last-child .card-text{
    font-size: .9rem;
    font-weight: 600;
    margin: 0;
}
.edition-li-card .card-body:last-child .card-text{
    color: var(--secondary-color);
    float: left;
}
.edition-li-card .card-body .nav {
    float: right;
}
.edition-li-card .card-body .nav .nav-item {
    margin-left: 2rem;
}
.edition-li-card .card-body .nav .nav-item a {
    color: var(--contrast-color);
}
.edition-li-card .card-body .nav .nav-item a:hover {
    color: var(--secondary-color);
    text-decoration: none;
}

#plano-tabs {
    padding-top: 1.75rem;
    border: none;
}

#plano-tabs>li {
    width: 33%;
    text-align: center;
    color: white;
    background-color: #00b4ae;
}

#plano-tabs>li a:hover {
    cursor: pointer;
    border: none;
}

#plano-tabs>li .active {
    border: none;
    text-align: center;
    color: white;
    font-weight: 600;
    background-color: #00b4ae;
    border-bottom: .2rem solid white;
}

#feather {
    width: 240px;
    height: 240px;
}

input.style-input-mask {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
#instituicao-tabs {
    padding-top: 1.75rem;
    border: none;
}

#instituicao-tabs>li {
    width: 50%;
    text-align: center;
    color: white;
    background-color: #00b4ae;
}

#instituicao-tabs>li a:hover {
    cursor: pointer;
    border: none;
}

#instituicao-tabs>li .active {
    border: none;
    text-align: center;
    color: white;
    font-weight: 600;
    background-color: #00b4ae;
    border-bottom: .2rem solid white;
}

#feather {
    width: 240px;
    height: 240px;
}

input.style-input-mask {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.react-toggle {
    touch-action: pan-x;
  
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
  }
  
  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    transition: all 0.2s ease;
  }
  
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }
  
  .react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
  }
  
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
  }
  
  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }
  
  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
    box-sizing: border-box;
    transition: all 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
  }
  
  .react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 2px 3px #0099E0;
  }
  
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0px 0px 5px 5px #0099E0;
  }
.Categorias{
    background: #ffffff;
    color:  #00b4ae;
    border:none;
    padding: .5rem 1rem;
    border-radius: 5px;
    margin-top: 2rem;
    margin-left: 3rem;
    font-size: 32px;
    width: 80%;
    height: 70px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.25);
}
.Categorias-btn{
    background: #f7a03f;
    color: #fafafa;
    border:none;
    border-radius: 3px;
    width: 40px;
    height: 40px;
    margin-left: 7px;
    margin-top: 7px;
    display: flex;
    float: right;
}
.Categorias-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
}
.Categorias-btn:focus{
    outline: 0;
}
.Categorias-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}
.conteudo-tamanho{
    width: 100%;
}
.categoria-disabled{
    background: #f2f2f2;
}
.Subcategorias{
    background: #ffffff;
    color:  #00b4ae;
    border:none;
    padding: .5rem 1rem;
    border-radius: 5px;
    margin-top: 2rem;
    margin-left: 3rem;
    font-size: 32px;
    width: 80%;
    height: 70px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.25);
}
.Subcategorias-btn{
    background: #f7a03f;
    color: #fafafa;
    border:none;
    border-radius: 3px;
    width: 40px;
    height: 40px;
    margin-left: 7px;
    margin-top: 7px;
    display: flex;
    float: right;
}
.Subcategorias-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
}
.Subcategorias-btn:focus{
    outline: 0;
}
.Subcategorias-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}
.conteudo-tamanho{
    width: 100%;
}
.Toastify__toast--success {
    background: #00b4ae !important;
 }
.Pergunta-select-row{
    height: 85px !important;
}
.Pergunta-Field{
    border:none;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
}
.Pergunta-Field:focus{
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    outline: none;
}
.Pergunta-Error{
    color: #ff8080;
    font-size: 12px;
}
.Pergunta-Select{
    border:none;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
    margin-right: 19px;
}
.Pergunta-Select2{
    border:none;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
}
.conteudo-tamanho{
    width: 100%;
}
.form-pergunta{
    width: 90%;
}

.select-curso-usuario{
    padding-top: 1.25rem;
}
.Tipos{
    background: #ffffff;
    color:  #00b4ae;
    border:none;
    padding: .5rem 1rem;
    border-radius: 5px;
    margin-top: 2rem;
    margin-left: 3rem;
    font-size: 32px;
    width: 80%;
    height: 70px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.25);
}
.Tipos-btn{
    background: #f7a03f;
    color: #fafafa;
    border:none;
    border-radius: 3px;
    width: 40px;
    height: 40px;
    margin-left: 7px;
    margin-top: 7px;
    display: flex;
    float: right;
}
.Tipos-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
}
.Tipos-btn:focus{
    outline: 0;
}
.Tipos-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}
.conteudo-tamanho{
    width: 100%;
}
.pergunta-card{
    margin-left: 15px;
    padding: 15px;
}
.pergunta-card-box-shadow{
    box-shadow: 2px 2px 4px rgba(0,0,0,.15);
}
.pergunta{
    font-size: 21px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #00b4ae;
}
.referencia{
    font-size: 16px;
    color: #cecece;
}
.tipo-subcat{
    font-size: 14px;
    color: #f7a03f;
}
.conteudo-tamanho{
    width: 100%;
}
.picker-row .DayPickerInput {
    border:none;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
    margin-right: 19px;
}

.dropzone {
    align-items: center;
    border:1px solid #919191;
    border-radius: 7px;
    color: #919191;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 1rem;
    padding: 1rem;
}
.dropzone h3,
.dropzone p,
.dropzone div,
.dropzone span {
    display: flex;
    justify-content: center;
}

.dropzone img.img-file {
    max-width: 300px;
}
.dropzone .fakeBtt {
    background-color: #f7a03f;
    border-radius: 7px;
    color: #fff;
    font-size: .9rem;
    font-weight: bold;
    height: 2rem;
    margin-top: 1em;
    padding: .3rem;
    width: 150px;
}

.dropzone:hover {
    border: 1px solid #a0a0a0;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    cursor: pointer;
}

.DayPicker-Day {
    border-radius: 0 !important;
}

.DayPicker-Day--selected {
    background-color: #00b4ae !important;
    border-radius: 50%;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
.pagination > .active > a{
    background-color: #24b3af ;
    border-color: #24b3af ;
    color: #fff;
  }
.pagination > li > a{
    border: 1px solid #24b3af ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #24b3af ;
    border-color: #24b3af;
    outline: none ;
  }
.pagination > li > a, .pagination > li > span{
    color: #24b3af
  }
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }
  .margin-nogutters-pagination{
    padding: 0rem !important;
  }
.back-btn-consulta{
    height: 100px;
    width: 100%;
    background: #00b4ae;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    display: flex;
}
.back-btn-consulta:hover{
    cursor: pointer;
}
.back-btn-consulta:focus{
    outline: 0;
}
.header-titulo{
    background: #00b4ae;
    color: #ffffff;
    border: 0;
    width: 30%;
    font-weight: bolder;
}

.novo-btn-consulta{
    background: #ffffff;
    color: #00b4ae;
    border:none;
    padding: .5rem 2rem;
    border-radius: 5px;
    margin-top: 1rem;
    font-weight: bold;
    font-size: 24px;
    height: 60px;
    align-content: center;
    float: right;
    -webkit-margin-end: auto;
            margin-inline-end: auto;
    border-radius: 20px;
}
.btn-patrocinador{
    padding: 0.1rem 2rem !important;
}
.novo-btn-consulta:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    background:rgba(247,160,63,.85);
    color: #ffffff;
}
.novo-btn-consulta:focus{
    outline: 0;
}
.novo-btn-consulta:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}

.novo-btn-consulta:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    background:rgba(247,160,63,.85);
    color: #ffffff;
}
.novo-btn-consulta:focus{
    outline: 0;
}
.active-consulta{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
    outline: 0;
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    background:rgba(247,160,63,.85) !important;
    color: #ffffff !important;
}

.label-field {
    font-weight: 700;
    padding: 10px 0  10px 0 !important;
    color: #00b4ae;
    text-align: center;
    margin: auto 0 auto 2rem;
    font-size: 1.1rem;
}

.field-date{
    margin: auto 0 auto 0;
}

.field-date .DayPickerInput input {
    width: 100%;
    border: 1px solid hsl(0,0%,80%) !important;
    padding: .375rem .75rem !important;
    box-sizing: border-box !important;
    border-radius: 0.7rem;
    height: calc(1.5em + .75rem);
}

.field-select {
    margin: auto 0 auto 0;
}

.field-select .div-wrapper {
    height: calc(1.5em + .75rem);
}

.field-button {
    margin: auto 0 auto 0;
}

.field-button .btn-consulta{
    background: #f7a03f;
    color: #ffffff;
    border: none;
    padding: .5rem 1.5rem;
    border-radius: 20px;
    font-weight: bold;
    font-size: 1.3rem;
    align-content: center;
}

.Categorias{
    background: #ffffff;
    color:  #00b4ae;
    border:none;
    padding: .5rem 1rem;
    border-radius: 5px;
    margin-top: 2rem;
    margin-left: 3rem;
    font-size: 32px;
    width: 80%;
    height: 70px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.25);
}
.Categorias-btn{
    background: #f7a03f;
    color: #fafafa;
    border:none;
    border-radius: 3px;
    width: 40px;
    height: 40px;
    margin-left: 7px;
    margin-top: 7px;
    display: flex;
    float: right;
}
.Categorias-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
}
.Categorias-btn:focus{
    outline: 0;
}
.Categorias-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}
.conteudo-tamanho{
    width: 100%;
}
.categoria-disabled{
    background: #f2f2f2;
}
.Categorias{
    background: #ffffff;
    color:  #00b4ae;
    border:none;
    padding: .5rem 1rem;
    border-radius: 5px;
    margin-top: 2rem;
    margin-left: 3rem;
    font-size: 32px;
    width: 80%;
    height: 70px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.25);
}
.Categorias-btn{
    background: #f7a03f;
    color: #fafafa;
    border:none;
    border-radius: 3px;
    width: 40px;
    height: 40px;
    margin-left: 7px;
    margin-top: 7px;
    display: flex;
    float: right;
}
.Categorias-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
}
.Categorias-btn:focus{
    outline: 0;
}
.Categorias-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}
.conteudo-tamanho{
    width: 100%;
}
.categoria-disabled{
    background: #f2f2f2;
}
.Toastify__toast--success {
    background: #00b4ae !important;
 }
 .Toastify__toast--error {
    background: red !important;
 }
.modal-title{
    width: 100%;
}
.Modal-header{
    background: #00b4ae;
}
.Modal-input{
    margin-bottom: 20px;
    margin-left: 18px;
    width: 95% !important;
}
.Modal-titulo{
    color: #ffffff;
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    margin-left: 15px;
    align-content: center;
}
.Modal-tamanho{
    width: 100% !important;
}
.Modal-btn{
    background: #f7a03f;
    color: #ffffff;
    border:none;
    border-radius: 5px;
    font-size: 24px;
    width: 120px;
    height: 40px;
    display: flex;
    float: right !important;
    align-content: center;
}
.Modal-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    background:rgba(247,160,63,.85);
}
.Modal-btn:focus{
    outline: 0;
    box-shadow: 2px 2px 4px rgba(0,0,0,.25);
}
.Modal-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}

.Toastify__toast--success {
    background: #00b4ae !important;
 }
.modal-title{
    width: 100%;
}
.Modal-header{
    background: #00b4ae;
}
.Modal-input{
    margin-bottom: 20px;
    margin-left: 18px;
    width: 95% !important;
}
.Modal-titulo{
    color: #ffffff;
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    margin-left: 15px;
    align-content: center;
}
.Modal-tamanho{
    width: 100% !important;
}
.Modal-btn{
    background: #f7a03f;
    color: #ffffff;
    border:none;
    border-radius: 5px;
    font-size: 24px;
    width: 120px;
    height: 40px;
    display: flex;
    float: right !important;
    align-content: center;
}
.Modal-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    background:rgba(247,160,63,.85);
}
.Modal-btn:focus{
    outline: 0;
    box-shadow: 2px 2px 4px rgba(0,0,0,.25);
}
.Modal-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}
.Svg-event-none{
    pointer-events: none;
}
.Toastify__toast--success {
    background: #00b4ae !important;
 }
.modal-title{
    width: 100%;
}
.Modal-header{
    background: #00b4ae;
}
.Modal-input{
    margin-bottom: 20px;
    margin-left: 18px;
    width: 95% !important;
}
.Modal-titulo{
    color: #ffffff;
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    margin-left: 15px;
    align-content: center;
}
.Modal-tamanho{
    width: 100% !important;
}
.Modal-btn{
    background: #f7a03f;
    color: #ffffff;
    border:none;
    border-radius: 5px;
    font-size: 24px;
    width: 120px;
    height: 40px;
    display: flex;
    float: right !important;
    align-content: center;
}
.Modal-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    background:rgba(247,160,63,.85);
}
.Modal-btn:focus{
    outline: 0;
    box-shadow: 2px 2px 4px rgba(0,0,0,.25);
}
.Modal-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}

.Toastify__toast--success {
    background: #00b4ae !important;
 }
.Pergunta-select-row{
    height: 85px !important;
}
.Pergunta-Field{
    border:none !important;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
    opacity: 0.7 !important;
    font-weight: bolder;
}
.Pergunta-Field:focus{
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    outline: none;
}
.Pergunta-Error{
    color: #ff8080;
    font-size: 12px;
}
.Pergunta-Select{
    border:none;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
    margin-right: 19px;
}
.Pergunta-Select2{
    border:none;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
}
.conteudo-tamanho{
    width: 90%;
    margin: 0 auto !important;
}
.form-pergunta{
    width: 90%;
}
.side-menu{
    box-shadow: 7px 7px 21px -10px rgba(0,0,0,0.5);
    min-height: 100vh;
}
.main-header{
    padding: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0;
}
.Login-Field{
    border:none;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 400px;
}

.Login-Field:focus{
    box-shadow: 2px 2px 4px rgba(0,0,0,.15);
    outline: none;
}
.Login-Error{
    color: #ff8080;
    font-size: 12px;
}
.Login-btn{
    background: #f7a03f;
    color: #ffffff;
    border:none;
    padding: .5rem 2rem;
    border-radius: 5px;
    margin-top: 1rem;
    font-size: 24px;
    width: 50%;
    height: 60px;
    align-content: center;
}
.Login-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    background:rgba(247,160,63,.85);
}
.Login-btn:focus{
    outline: 0;
    box-shadow: 2px 2px 4px rgba(0,0,0,.25);
}
.Login-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}
.align-content-middle {
    display: flex;
    align-items: center;
    justify-content: center;
}
.img-lateral{
    /* background: url('../../img/fundo.png') left; */
    padding: 0 !important;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    max-height: 100vh;
}
.img-lateral > img{
    height: 100%;
    width: 100%;
}
.customIcon{
    position: absolute;
    left: auto;
    right: 45px;
    text-indent: 32px;
    top: 50px;
}
.error-email{
    border: 1px solid #ff8080;
    border-radius: 5px;
    padding-left: 9px;
    width: 180px;
    color: #ff8080;
    position: relative;
    top: 11px;
}
.error-password{
    margin-left: 20px;
    border: 1px solid #ff8080;
    border-radius: 5px;
    padding-left: 9px;
    width: 180px;
    color: #ff8080;
    /*color: #adb5bd;*/
}


/* Root CSS */
:root {
    --main-color: #24b3af;
    --secondary-color: #ffa140;
    --contrast-color: #666;
}
/* Resets */
body {
    font-size: 100% !important;
    font-weight: normal !important;
    font-family: 'Lato', 'Roboto', arial, sans-serif !important;
}

/* Helpers */
.main-header .no-gutters.row {
    padding: 4rem;
    justify-content: space-between;
}
.no-gutters > .nav {
    justify-content: center;
    width: 100%;
}
.no-gutters > .nav .nav-item {
    max-width: 980px;
}

.counter-card,
.counter-card2,
.edition-li-card .card,
.graph-card,
.grid-card,
.pre-edition-li-card,
.search-bar.with-filter button,
.search-bar.with-filter .input-group,
.search-bar.filter-graphic .input-group
 { 
    border: 1px solid rgba(0,0,0,0.075);
    border-radius: 20px;
    box-shadow: 3px 5px 5px -2px rgba(0,0,0,.15);
    margin-bottom: 40px;
}
.counter-card:active,
.counter-card2:active,
.graph-card:active,
.grid-card:active,
.pre-edition-li-card:active,
.counter-card:hover,
.counter-card2:hover,
.edition-li-card .card:hover,
.graph-card:hover,
.grid-card:hover,
.pre-edition-li-card:hover,
.search-bar.with-filter button:hover,
.search-bar.with-filter button:focus,
.search-bar.with-filter .input-group:hover,
.search-bar.filter-graphic button:hover,
.search-bar.filter-graphic button:focus,
.search-bar.filter-graphic .input-group:hover {
    box-shadow: 2px 2px 5px rgba(0,0,0,.25);
}

.wrap-text-without-space {
    white-space: pre-wrap;      
    word-wrap: break-word; 
}
.no-gutters>[class*=col-].grid-card{
    padding: 1.2rem;
}
.no-gutters.row.grid-container{
    justify-content: flex-start;
}
.no-gutters.row.grid-container .grid-card{
    margin: 1.2rem;
    padding: 1.2rem;
    height: 80%
}
/********************SPINNER LOADER***********************/

.overlay {
    opacity: 0.8;
    background-color: #ccc;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 15000;
    display: flex
}

.loader {
    margin: auto;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #24b3af;
    border-top: 16px solid var(--main-color);
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

#desafioROW > div.col-md-6 > div{
    width: 100%;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.Reset-Field{
    border:none;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
}

.Reset-Field:focus{
    box-shadow: 2px 2px 4px rgba(0,0,0,.15);
    outline: none;
}
.Reset-Error{
    color: #ff8080;
    font-size: 12px;
}
.Reset-btn{
    background: #f7a03f;
    color: #ffffff;
    border:none;
    padding: .5rem 2rem;
    border-radius: 5px;
    font-size: 18px;
    width: 50%;
    max-width: 120px;
    height: 40px;
    align-content: center;
}
.Reset-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    background:rgba(247,160,63,.85);
}
.Reset-btn:focus{
    outline: 0;
    box-shadow: 2px 2px 4px rgba(0,0,0,.25);
}
.Reset-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}
.align-content-middle {
    display: flex;
    align-items: center;
    justify-content: center;
}
.customIconReset{
    position: absolute;
    left: auto;
    right: 25px;
    text-indent: 32px;
    top: 35px;
}
.error-email-Reset{
    border: 1px solid #ff8080;
    border-radius: 5px;
    width: 150px;
    color: #ff8080;
    position: relative;
    top: 9px;
    font-size: 12px;
    text-align: center;

}
.error-password-Reset{
    margin-left: 20px;
    border: 1px solid #ff8080;
    border-radius: 5px;
    width: 150px;
    color: #ff8080;
    position: relative;
    top: 9px;
    font-size: 12px;
    text-align: center;
}


