.edition-li-card .card {
    padding: 0 .5rem;
}

.edition-li-card .card-title {
    color: var(--main-color);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.6rem;
}
.edition-li-card .card-subtitle {
    color: var(--contrast-color);
    font-size: 1rem;
    font-weight: 100;
}

.edition-li-card .card-body:last-child {
    padding-top: 0;
}
.edition-li-card .card-body .nav,
.edition-li-card .card-body:last-child .card-text{
    font-size: .9rem;
    font-weight: 600;
    margin: 0;
}
.edition-li-card .card-body:last-child .card-text{
    color: var(--secondary-color);
    float: left;
}
.edition-li-card .card-body .nav {
    float: right;
}
.edition-li-card .card-body .nav .nav-item {
    margin-left: 2rem;
}
.edition-li-card .card-body .nav .nav-item a {
    color: var(--contrast-color);
}
.edition-li-card .card-body .nav .nav-item a:hover {
    color: var(--secondary-color);
    text-decoration: none;
}
