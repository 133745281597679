.search-bar.with-filter {
    width: 100%;
    display: flex;
}

.search-bar.with-filter .input-group {
    align-items: center;
    height: 4rem;
}

.search-bar.with-filter .input-group-prepend {
    padding: 0 1rem;
}

.search-bar.with-filter .input-group input {
    border: 0;
    background-color: transparent;
    color: var(--contrast-color);
    font-size: 1.2rem;
    padding: 2rem 0;
}

.search-bar.with-filter .input-group input:focus  {
    box-shadow: none;
}

.search-bar.with-filter button,
.search-bar.with-filter button.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: var(--secondary-color);
    display: flex;
        align-items: center;
        justify-content: space-evenly;
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 3rem;
    max-width: 160px;
    min-width: 90px;
    width: 100%;
}
.search-bar.with-filter 
    button.btn-secondary:not(:disabled):not(.disabled):active {
    border: 0;
    box-shadow: none;
}
