.grid-card h2 {
    color: #00b4ae;
    font-size: 1.6rem;
    font-weight: bold;
    margin: 0;
  }
  
  .grid-card h3 {
    color: #f7a03f;
    font-size: 1rem;
  }
  
  .grid-card p {
    color: #b2b2b2;
    margin: 1rem 0 0;
  }

  .grid-card:hover{
    box-shadow: 0px 0px 5px 0px rgb(254 186 69) !important;
    cursor: pointer;
  }
  .grid-status-false{
    background-color: #e5e5e5 !important;
    cursor: pointer !important;
    color: #b2b2b2 !important;
  }
  .grid-status-false h3 {
    color: #b2b2b2;
  }
  .grid-status-false h2 {
    color: #b2b2b2;
  }
  .grid-status-false:hover{
    box-shadow: 3px 5px 5px -2px rgba(0,0,0,.15) !important;
  }
  .text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }