.no-gutters.row > .graph-card, /* Para vencer a hierarquia */
.graph-card {
    padding: 1rem;
}

.graph-card-size {
    max-width: 47% !important;
    margin: auto;
}

.graph-card .header {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
}

.graph-card .header h2 {
    color: var(--main-color);
    font-size: 1.8rem;
    font-weight: bold;
}

.graph-card .buttons-container input {
    display: none;
}
.graph-card .buttons-container label {
    background-color:#fff;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    font-size: .8rem;
    height: 2rem;
    line-height: 2rem;
    margin: 0;
    text-align: center;
    width: 5rem;
}
.graph-card .buttons-container label:hover {
    cursor: pointer;
}
.graph-card .buttons-container input:checked + label {
    background-color: var(--secondary-color);
    color: #fff;
    cursor: default;
}
.graph-card .buttons-container .firstOption + label {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
    border-right: 0;
}
.graph-card .buttons-container .quiteOption + label {
    border-right: 0;
    border-left: 0;
}
.graph-card .buttons-container .secondOption + label {
    border-left: 0;
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
}

.graph-card .custom-tooltip .label{
    background: var(--secondary-color);
    border-radius: 1rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    width: 12rem;
}
.graph-card .custom-tooltip .two-label,
.graph-card .custom-tooltip .two-label-inverted{
    font-size: 1.2rem;
}

.graph-card .recharts-legend-item.legend-item-0 .recharts-legend-item-text,
.graph-card .custom-tooltip .two-label span:first-child,
.graph-card .custom-tooltip .two-label-inverted span:last-child{ 
    color: var(--main-color);
    font-weight: 700;
 }

.graph-card .recharts-legend-item.legend-item-1 .recharts-legend-item-text,
.graph-card .custom-tooltip .two-label span:last-child,
.graph-card .custom-tooltip .two-label-inverted span:first-child{ 
    color: var(--secondary-color);
    font-weight: 700;
}

.recharts-legend-item-text {
    color: black !important;
}


/* Aaui */
