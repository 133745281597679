
.no-gutters.row > .table-content, /* Para vencer a hierarquia */
  .table-content {
    border-spacing: 0;
    width: 100%;
  }

  .table-content th{
        color: #A9A9A9;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        line-height: 24px;
        border-bottom: 1px solid #DFE2E9;  
        align-items: center;
      
  }
  .table-content td {
        margin: 0;
        padding: 0.5rem;
        color: #00072B;
        font-family: Montserrat;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        border-bottom: 1px solid #DFE2E9;
        font-family: Montserrat;
    }
    .rgt-text-truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .paginationin-content {
        justify-content: flex-end !important;
        color: #FFA140;
    }
    .button-paginationin {
        padding: 10px;
        border: 1px solid #FFA140;
        color: #FFA140;
        margin: 10px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 5px 5px 5px 5px #e8e8e8;
    }
    