.Reset-Field{
    border:none;
    background: #f2f2f2;
    color: black;
    margin-top: 1rem;
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 7px;
    transition: all .1s linear;
    width: 100%;
}

.Reset-Field:focus{
    box-shadow: 2px 2px 4px rgba(0,0,0,.15);
    outline: none;
}
.Reset-Error{
    color: #ff8080;
    font-size: 12px;
}
.Reset-btn{
    background: #f7a03f;
    color: #ffffff;
    border:none;
    padding: .5rem 2rem;
    border-radius: 5px;
    font-size: 18px;
    width: 50%;
    max-width: 120px;
    height: 40px;
    align-content: center;
}
.Reset-btn:hover{
    cursor: pointer;
    box-shadow: 2px 2px 4px rgba(0,0,0,.5);
    background:rgba(247,160,63,.85);
}
.Reset-btn:focus{
    outline: 0;
    box-shadow: 2px 2px 4px rgba(0,0,0,.25);
}
.Reset-btn:active{
    box-shadow: -2px -2px 4px rgba(0,0,0,.5);
}
.align-content-middle {
    display: flex;
    align-items: center;
    justify-content: center;
}
.customIconReset{
    position: absolute;
    left: auto;
    right: 25px;
    text-indent: 32px;
    top: 35px;
}
.error-email-Reset{
    border: 1px solid #ff8080;
    border-radius: 5px;
    width: 150px;
    color: #ff8080;
    position: relative;
    top: 9px;
    font-size: 12px;
    text-align: center;

}
.error-password-Reset{
    margin-left: 20px;
    border: 1px solid #ff8080;
    border-radius: 5px;
    width: 150px;
    color: #ff8080;
    position: relative;
    top: 9px;
    font-size: 12px;
    text-align: center;
}

