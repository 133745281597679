
.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
.pagination > .active > a{
    background-color: #24b3af ;
    border-color: #24b3af ;
    color: #fff;
  }
.pagination > li > a{
    border: 1px solid #24b3af ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #24b3af ;
    border-color: #24b3af;
    outline: none ;
  }
.pagination > li > a, .pagination > li > span{
    color: #24b3af
  }
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }
  .margin-nogutters-pagination{
    padding: 0rem !important;
  }