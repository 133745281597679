.no-gutters.row > .counter-card2, /* Para vencer a hierarquia */
.counter-card2 {
    /* display: flex; */
    justify-content: space-evenly;
    /* max-width: 320px; */
    padding: 1.5rem;
}

.counter-card2 svg {
    min-width: 60px;
}
.counter-card2 .info {
    display: flex;
        align-items: center;
        flex-flow: column;
}

.counter-card2 .info .count {
    color: var(--secondary-color);
    font-size: 2.4rem;
    font-weight: 900;
    line-height: 2.6rem;
}
.counter-card2 .info .legend {
    color: var(--main-color);
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.counter-card2 .info .sublegend {
    color: var(--secondary-color);
    font-size: .75rem;
    font-weight: 600;
    text-align: center;
}