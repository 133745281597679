.pre-edition-li-card.nav-item {
    width: 100%;
}
.pre-edition-li-card.nav-item a {
    display: flex;
}
.pre-edition-li-card.nav-item a:hover{
    text-decoration: none;
}
.pre-edition-li-card .container {
    color: var(--secondary-color);
    font-size: 2rem;
    font-weight: 900;
    height: 6rem;
    padding: 1.5rem 2rem;
}

.pre-edition-li-card .container .icon-left,
.pre-edition-li-card .container .icon-right {
    color: var(--main-color);
}
.pre-edition-li-card .container img,
.pre-edition-li-card .container .icon-left {
    float: left;
    margin-right: 2.5rem;
}

.pre-edition-li-card .container .icon-right {
    float: right;
}
